import Mock from "../mock";

const database = {
  information: {
    name: 'Eli Zhang',
    aboutContent: "Eli is an experienced and self-motivated software engineer with a background in bringing impressive results while working with well-known companies. Result-oriented with a proven track record of working collaboratively with team members to achieve the goals. Experienced in building and managing both mobile and web development.\n\nHe is a Senior software engineer with skills in Programming, Requirement Gathering, Product Management, System Specification, and System Design.",
    phone: '(929) 249-2419',
    email: 'me@elizhang.dev',
    address: '99 Peel St S, St. Marys, ON, Canada',
    freelanceStatus: 'Open to offers',
    socialLinks: {
      facebook: '',
      twitter: 'https://twitter.com/elizhang221',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/eli-zhang221/',
      dribbble: '',
      github: 'https://github.com/creative-dev-lab'
    },
    brandImage: '/images/logo.png',
    aboutImage: '/images/background.jpeg',
    aboutImageLg: '/images/background.jpeg',
    cvfile: '/files/Elizhang.pdf'
  },
  services: [
    {
      title: "UI/UX Design",
      icon: 'pallet',
      details: "Web Design\nMobile App Design\nInterface Design"
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Website Frontend Development\nWeb App Development\nWeb Portal Development"
    },
    {
      title: "Mobile App Development",
      icon: 'mobile',
      details: "iOS App Development\nAndroid App Development\nCross-Platform Development"
    }
  ],
  reviews: [
    {
      id: 1,
      content: "What a relif! I finally found a real dedicated and professional developer. My project was too complicated to be completed, so I was about to give up my project. Just a last shot, I gave my project to Eli and he made it happen! I really appreciate the quality work he has done. Definitely highly recommend him!",
      author: {
        name: 'Mario Molina',
        designation: 'CEO & Founder of Webllywood'
      }
    },
    {
      id: 2,
      content: "This was a fantastic - first experience. I was trying to figure out a good way of getting this service done. I am a residential developer and didn’t have much time to work on this. Eli kicked in and knocked it out the park. We had a few chats that gave him direction and he produced. I will be back to work with Eli.",
      author: {
        name: 'Ash R.',
        designation: 'Contract'
      }
    },
    {
      id: 3,
      content: "I'm giving high five to Eli, because I'm very satisfied with the result. It was not an easy job I believe, But Eli managed very well and Well Done. Highly recommended!",
      author: {
        name: 'Kareem Waterman',
        designation: 'Founder of Luna'
      }
    },
    {
      id: 4,
      content: "Very happy with the quality of work and his ability to take what I needed and turn it into a case study that matched what I was hoping!",
      author: {
        name: 'Justin Adelman',
        designation: 'Contract'
      }
    },
    {
      id: 5,
      content: "Great to work with Eli! He is one of the most competent developers who I ever met so far. I definitely hire him again for my future projects! Thanks Eli, for your awesome work!",
      author: {
        name: 'Roman Steele',
        designation: 'Contract'
      }
    },
    {
      id: 6,
      content: "Excellent programmer! One of, if not, the best I've found in 5 years on my career. I highly recommend using Eli Zhang for any project. He exceeded my expectation. Will be working with him again in the future.",
      author: {
        name: 'Tara Sims',
        designation: 'Contract'
      }
    },
    {
      id: 7,
      content: "As always, work is great. Thank Eli for always providing me great work.",
      author: {
        name: 'Jayson Williamson',
        designation: 'Contract'
      }
    },
    {
      id: 8,
      content: "很专业。非常乐于协助和进行更改。易于沟通。强烈推荐。",
      author: {
        name: '崔天',
        designation: 'Local Employer'
      }
    },
    {
      id: 9,
      content: "Eli went above and beyond on my project and I cannot recommend him enough. We worked together to design something that was out of scope of my original investment but Eli took the time to research how to get what we needed done and apply it masterfully. I have hired him again and will continue to work with him on many future projects. Thank you!",
      author: {
        name: 'Max S.',
        designation: 'Contract'
      }
    },
    {
      id: 10,
      content: "It was a real pleasure working with Eli. He understood the task and delivered everything up to the last detail, days before the deadline. He is very patient and professional. No doubt that I would work with him again.",
      author: {
        name: 'Vladimir S.',
        designation: 'Contract'
      }
    },
  ],
  skills: [
    {
      title: "iOS - Swift/SwiftUI",
      value: 100
    },
    {
      title: "Android - Java/Kotlin",
      value: 100
    },
    {
      title: "ReactJS",
      value: 100
    },
    {
      title: "React Native",
      value: 100
    },
    {
      title: "Flutter/Dart",
      value: 90
    },
    {
      title: "Javascript",
      value: 100
    },
    {
      title: "Typescript",
      value: 100
    },
    {
      title: "GraphQL",
      value: 90
    },
    {
      title: "Node.js",
      value: 90
    },
    {
      title: "AngularJS",
      value: 100
    },
    {
      title: "Angular",
      value: 80
    },
    {
      title: "HTML5",
      value: 90
    },
    {
      title: "CSS3",
      value: 95
    },
    {
      title: "jQuery",
      value: 90
    },
    {
      title: "Laravel",
      value: 90
    },
    {
      title: ".Net Core",
      value: 90
    },
    {
      title: "Git",
      value: 100
    },
    {
      title: "Photoshop",
      value: 80
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Grove Collaborative",
      subtitle: "Grove started as an idea between three friends talking in a spare bedroom in San Francisco. They are to help you create customer's best version of home with ease and enjoyment.\n\nRole: FullStack Web Dev\nUsed Tech Stacks: Laravel, Php, Html, CSS, MySQL, JQuery",
      imageUrl: "/images/Grove_small.png",
      largeImageUrl: [
        "/images/Grove.png",
        "/images/Grove1.png",
        "/images/Grove2.png",
        "/images/Grove3.png",
      ],
      url: 'https://www.grove.co/'
    },
    {
      id: 2,
      title: "Grove Collaborative Mobile",
      subtitle: "iOS & Android apps for Grove Collaborative website.\n\nRole: FullStack Mobile Dev\nUsed Tech Stacks: Swift, Kotlin",
      imageUrl: "/images/GroveMobile_small.png",
      largeImageUrl: [
        "/images/GroveMobile.png",
      ],
      ios: 'https://apps.apple.com/us/app/grove-collaborative/id1452655594',
      android: 'https://play.google.com/store/apps/details?id=co.grove.android'
    },
    {
      id: 3,
      title: "The Knot",
      subtitle: "In 15 countries around the world, The Knot Worldwide’s leading global family of brands inspire, inform and celebrate our communities as they move through life`s biggest milestones. From the proposal to the wedding day, creating a home to starting a family together, they’re there for them every step of the way.\n\nRole: FrontEnd Dev & Designer\nUsed Tech Stacks: React.js, Redux.js, Redux Saga",
      imageUrl: "/images/TheKnot_small.png",
      largeImageUrl: [
        "/images/TheKnot.png",
        "/images/TheKnot1.png",
        "/images/TheKnot2.png",
        "/images/TheKnot3.png",
        "/images/TheKnot4.png",
        "/images/TheKnot5.png",
      ],
      url: 'https://www.theknot.com/'
    },
    {
      id: 4,
      title: "The Knot Mobile",
      subtitle: "iOS & Android apps for The Knot.\n\nRole: FrontEnd Mobile Dev\nUsed Tech Stacks: Swift, Kotlin",
      imageUrl: "/images/TheKnotMobile_small.png",
      largeImageUrl: [
        "/images/TheKnotMobile.png",
        "/images/TheKnotMobile1.png",
        "/images/TheKnotMobile2.png",
      ],
      ios: 'https://itunes.apple.com/us/app/wedding-planner-by-the-knot/id457941553',
      android: 'https://play.google.com/store/apps/details?id=com.xogrp.planner'
    },
    {
      id: 5,
      title: "Journi",
      subtitle: "Whether you`re a mother creating a family photo book of your kids, or you`re a well-traveled adventurer looking to make a photo album of your last trip, you most likely don`t want it to have a negative impact on the environment. Well, the good news is: with Journi, it doesn`t.\n\nRole: FrontEnd Dev\nUsed Tech Stacks: React.js Redux, Redux Saga",
      imageUrl: "/images/JourniApp_small.png",
      largeImageUrl: [
        "/images/JourniApp.png",
        "/images/JourniApp1.png",
        "/images/JourniApp2.png",
        "/images/JourniApp3.png",
        "/images/JourniApp4.png",
        "/images/JourniApp5.png",
      ],
      url: 'https://www.journiapp.com/'
    },
    {
      id: 6,
      title: "Journi Mobile",
      subtitle: "iOS & Android apps for JourniApp.\n\nRole: FrontEnd Mobile Dev\nUsed Tech Stacks: React Native",
      imageUrl: "/images/JourniMobile_small.png",
      largeImageUrl: [
        "/images/JourniMobile.png",
        "/images/JourniMobile1.png",
      ],
      ios: 'https://apps.apple.com/us/app/grove-collaborative/id1452655594',
      android: 'https://play.google.com/store/apps/details?id=co.grove.android'
    },
    {
      id: 7,
      title: "Bring Fido",
      subtitle: "BringFido is the world's leading pet travel and lifestyle brand. It connects millions of pet owners around the globe with more than 250,000 pet friendly places.\n\nRole: FrontEnd Web Dev\nUsed Tech Stacks: React.js, Redux, Redux-Thunk",
      imageUrl: "/images/BringFido_small.png",
      largeImageUrl: [
        "/images/BringFido.png",
        "/images/BringFido1.png",
        "/images/BringFido2.png",
        "/images/BringFido3.png",
      ],
      url: 'https://www.bringfido.com/'
    },
    {
      id: 8,
      title: "Bring Fido Mobile",
      subtitle: "iOS & Android apps for BringFido website.\n\nRole: FrontEnd Mobile Dev\nUsed Tech Stacks: React Native",
      imageUrl: "/images/BringFidoMobile_small.png",
      largeImageUrl: [
        "/images/BringFidoMobile1.png",
      ],
      ios: 'https://apps.apple.com/us/app/bringfido-pet-friendly-hotels/id682820712',
      android: 'https://play.google.com/store/apps/details?id=com.bringfido.bringfido'
    },
    {
      id: 9,
      title: "Eat Smart",
      subtitle: "EatSmart leverage theirknowledge of the world of vegetables and other simply delicious ingredients so that they can deliver to the customers the meals they'll love crave.\n\nRole: BackEnd Dev\nUsed Tech Stacks: Node.js, Express.js, MongoDB",
      imageUrl: "/images/EatSmart_small.png",
      largeImageUrl: [
        "/images/EatSmart.png",
        "/images/EatSmart1.png",
        "/images/EatSmart2.png",
        "/images/EatSmart3.png",
      ],
      url: 'https://www.eatsmart.net/'
    },
    {
      id: 10,
      title: "Webllywood",
      subtitle: "Webllywood is a place where everyone has the opportunity to show their art to the world and where they can enjoy art in its various forms. They believe that everyone has a story to tell the world and we are committed to giving them the opportunity to do so in order to show their talent to the world.\n\nRole: FullStack Web Dev & Designer\nUsed Tech Stacks: Codeignitor, CorePhp, Html, CSS, Jquery, Bootstrap",
      imageUrl: "/images/Webllywood_small.png",
      largeImageUrl: [
        "/images/Webllywood.png",
        "/images/Webllywood7.png",
        "/images/Webllywood2.png",
        "/images/Webllywood3.png",
        "/images/Webllywood4.png",
        "/images/Webllywood5.png",
        "/images/Webllywood6.png",
        "/images/Webllywood1.png",
      ],
      url: 'https://www.webllywood.com/'
    },
    {
      id: 11,
      title: "Webllywood Mobile",
      subtitle: "iOS & Android apps for Webllywood.\n\nRole: FullStack Mobile Dev & Designer\nUsed Tech Stacks: React Native",
      imageUrl: "/images/WebllywoodMobile_small.png",
      largeImageUrl: [
        "/images/WebllywoodMobile.png",
        "/images/WebllywoodMobile1.png",
      ],
      ios: 'https://itunes.apple.com/us/app/webllywood/id1336392130',
      android: 'https://play.google.com/store/apps/details?id=com.webllywood.app'
    },
    {
      id: 12,
      title: "Music & Audio Player",
      subtitle: "Music & Audio Player is a Stylish, Powerful and Fast Music Player which combines the built-in 10 bands equalizer to bring great sound effects and the best musical experience. Music & Audio Player is a powerful music player for Android with bass boost, reverb effects and the built-in equalizer can fulfill all your musical needs.\n\nRole: FullStack Mobile Dev & Designer\nUsed Tech Stacks: Android Studio, Java, XCode, Swift, Node.js, MongoDB",
      imageUrl: "/images/MusicPlayer_small.png",
      largeImageUrl: [
        "/images/MusicPlayer.png",
      ],
    },
    {
      id: 13,
      title: "Mellow Mushroom",
      subtitle: "Mellow Mushroom Pizza Bakers was founded in 1974 in Atlanta, Georgia. The franchise, operated under Home-Grown Industries of Georgia, Inc., is headquartered in Atlanta, Georgia. For over 40 years, Mellow Mushroom Pizza Bakers has been serving up fresh, stone-baked pizzas to order in an eclectic, art-filled, and family-friendly environment.\n\nRole: BackEnd Dev\nUsed Tech Stacks: Node.js, Express.js, MongoDB",
      imageUrl: "/images/MellowMushroom_small.png",
      largeImageUrl: [
        "/images/MellowMushroom.png",
        "/images/MellowMushroom1.png",
        "/images/MellowMushroom2.png",
        "/images/MellowMushroom3.png",
        "/images/MellowMushroom4.png",
      ],
      url: 'https://mellowmushroom.com/'
    },
    {
      id: 14,
      title: "Mellow Mushroom Mobile",
      subtitle: "iOS & Android apps for Mellow Mushroom.\n\nRole: FullStack Mobile Dev\nUsed Tech Stacks: React Native, Node.js MongoDB",
      imageUrl: "/images/MellowMushroomMobile_small.png",
      largeImageUrl: [
        "/images/MellowMushroomMobile.png",
      ],
      ios: 'https://itunes.apple.com/us/app/mellow-mushroom/id1313771632',
      android: 'https://play.google.com/store/apps/details?id=com.mellowmushroom.app'
    },
    {
      id: 15,
      title: "Nautica",
      subtitle: "Nautica is a leading global lifestyle brand for men, women, and children, which includes home bedding collections. As a nautical-influenced classic American sportswear brand, we inspire and enable people to experience the joy of water.\n\nRole: FrontEnd Dev\nUsed Tech Stacks: React.js, Redux, Redux saga",
      imageUrl: "/images/Nautica_small.png",
      largeImageUrl: [
        "/images/Nautica.png",
        "/images/Nautica1.png",
        "/images/Nautica2.png",
        "/images/Nautica3.png",
        "/images/Nautica4.png",
        "/images/Nautica5.png",
      ],
      url: 'https://www.nautica.com/'
    },
    {
      id: 16,
      title: "PizzaT",
      subtitle: "PizzaT is a pizza reservation app which offers time-based discounts of up to 50% off, every day at all of its restaurants. There are no pre-payments or hidden cost, PizzaT is 100% free.\n\nRole: FullStack Mobile Dev & Designer\nUsed Tech Stacks: Swift, Java, Node.js, MongoDB",
      imageUrl: "/images/PizzaTMain_small.png",
      largeImageUrl: [
        "/images/PizzaTMain.png",
        "/images/PizzaT1.png",
        "/images/PizzaT2.png",
        "/images/PizzaT.png",
      ],
    },
    {
      id: 17,
      title: "Travel Juneau",
      subtitle: "Travel Juneau (formerly the Juneau Convention & Visitors Bureau) is a private, nonprofit organization dedicated to the visitor industry and its economic benefit to Juneau, Alaska. Travel Juneau's primary goal is to increase the overnight stays of business and leisure travelers, who in turn support local businesses through their spending.\n\nRole: FrontEnd Dev\nUsed Tech Stacks: React.js",
      imageUrl: "/images/TravelJuneau_small.png",
      largeImageUrl: [
        "/images/TravelJuneau.png",
        "/images/TravelJuneau1.png",
        "/images/TravelJuneau2.png",
        "/images/TravelJuneau3.png",
        "/images/TravelJuneau4.png",
        "/images/TravelJuneau5.png",
      ],
      url: 'https://www.traveljuneau.com/'
    },
    {
      id: 18,
      title: "Yuen Lui Studio",
      subtitle: "Yuen Lui Studio has been creating beautiful photographic portraits for families in the Pacific Northwest for over 70 years. They offer consistent results that only experienced professional photographers can achieve.\n\nRole: MEAN Stack Dev\nUsed Tech Stacks: MongoDB, Express.js, Angular, Node.js",
      imageUrl: "/images/YuenLuiStudio_small.png",
      largeImageUrl: [
        "/images/YuenLuiStudio.png",
        "/images/YuenLuiStudio1.png",
        "/images/YuenLuiStudio2.png",
        "/images/YuenLuiStudio3.png",
      ],
      url: 'http://www.yuenluistudio.com/'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2021 - Present",
        position: "Senior Mobile Engineer",
        company: "AirRobe",
        details: "AirRobe is powering the future of sustainable fashion. We are a world-first technology solution that connects brands and customers to the circular economy - in one click.\n\nMy responsibilities here:\n• Responsible for building & maintaining iOS & android widget SDKs written in Kotlin and Swift.\n• Responsible for building out the mobile version of the circular wardrobe web app written in React Native."
      },
      {
        id: 2,
        year: "2020 - 2021",
        position: "Senior Mobile Engineer",
        company: "Grove Collabrative",
        details: "•	Worked as a Full-Time Mobile developer.\n•	Responsible for fixing bugs/adding new features.\n•	Responsible for updating/maintaining Website as needed.\n• Responsible for problem-solving and being a fallback for less experienced colleagues.\n•	Successfully published 20+ times of updated versions to the app stores."
      },
      {
        id: 3,
        year: "2018 - 2020",
        position: "Senior Mobile Engineer",
        company: "The Knot",
        details: "•	Worked as a Full-Time Mobile developer.\n•	Responsible for Coding, QAing, Contributing ideas on UX, Managing sub-modules along with 2 other front-end devs.\n•	Responsible for updating/maintaining the website as needed.\n• Responsible for problem-solving and being a fallback for less experienced colleagues.\n•	Successfully published 50+ times of updated versions to the app stores."
      },
      {
        id: 4,
        year: "2016 - 2017",
        position: "Software Engineer",
        company: "Gentleware",
        details: "• Worked as a Full-Time software engineer.\n• Worked with a team of 16 programmers on various company projects, including the development of several large-scale Mobile/Web apps from the ground up.\n• Responsible for site architecture, test plans, and software development of various components."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2011 - 2015",
        graduation: "Bachelor of Computer Science(BCS)",
        university: "California Institute of Technology",
        details: "• Captain of the college debate team in programming\n• Attended courses on Object-oriented Programming, Mobile/Web Design, Databases, etc.\n• Honored Bachelor's degree in computer science"
      },
    ],
    otherExperiences: [
      {
        id: 1,
        year: "2019 - 2020",
        position: "Owner / Startup Entrepreneur",
        company: "Digitized Profit",
        details: "• Started to work on my own business which is related to internet marketing. (2019)\n• Gained very deep knowledge about WordPress, SEO, SMO, Email Marketing, Content Marketing, Monetization Strategy, etc.\n• Have made significant outcomes with affiliate marketing.\n• Schedule to resume the business in the next 5 years."
      },
    ]
  },
  blogs: [
    {
      id: 1,
      title: "React new version is coming!",
      imageUrl: "/images/blog-image-1.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 2,
      title: "More about react hooks",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "04 December 2019"
    },
    {
      id: 3,
      title: "Next generation javascript learning source",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "05 December 2019"
    },
    {
      id: 4,
      title: "Free tutorial downloading link.",
      imageUrl: "/images/blog-image-2.jpg",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "06 December 2019"
    },
    {
      id: 5,
      title: "Get 2500 free mockup for design.",
      youtubeUrl: "TKnufs85hXk",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "08 December 2019"
    },
    {
      id: 6,
      title: "React vs Vue vs Angular, what is best?",
      vimeoUrl: "23534361",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "10 December 2019"
    },
    {
      id: 7,
      title: "Web design typography & spacing.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    },
    {
      id: 8,
      title: "React new version is coming with a great features.",
      content: "ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour. ReactJS is the most popular javascript framework now a days. Now it has comes with more and more new features. There is something called hooks, which has a great flavour.",
      createTime: "03 December 2019"
    }
  ],
  contactInfo: {
    phoneNumbers: ['(929) 249-2419'],
    emailAddress: ['me@elizhang.dev', 'elizhang221@gmail.com'],
    address: "St. Marys, ON, Canada"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";

function Home() {
  const [information, setInformation] = useState("");
  const paramConfig = {
    backgroundMode: {
      enable: true,
      zIndex: 0
    },
    background: {
      color: "#000"
    },
    fpsLimit: 60,
    interactivity: {
      detectsOn: "canvas",
      events: {
        onClick: { enable: true, mode: "repulse" },
        onHover: {
          enable: true,
          mode: "bubble",
          parallax: { enable: false, force: 2, smooth: 10 }
        },
        resize: true
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 0.3,
          opacity: 1,
          size: 4,
          speed: 3
        },
        grab: { distance: 400, line_linked: { opacity: 0.5 } },
        push: { particles_nb: 4 },
        remove: { particles_nb: 2 },
        repulse: { distance: 200, duration: 0.4 }
      }
    },
    particles: {
      color: { value: "#fff" },
      links: {
        color: "#ffffff",
        distance: 500,
        enable: false,
        opacity: 0.4,
        width: 2
      },
      move: {
        attract: { enable: false, rotateX: 600, rotateY: 1200 },
        direction: "top",
        enable: true,
        outMode: "out",
        random: false,
        size: true,
        speed: 2,
        straight: false
      },
      number: { density: { enable: true, area: 800 }, value: 100 },
      opacity: {
        random: true,
        value: 0.5
      },
      shape: {
        type: "circle"
      },
      size: {
        random: true,
        value: 5
      }
    },
    detectRetina: true
  };

  useEffect(() =>{
    axios.get('/api/information')
    .then( response => {
      setInformation(response.data);
    })
  }, [])

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log(container);
  }, []);

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          className="mi-home-particle"
          options={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I am <span className="color-theme">{information.name}</span>
                </h1>
                <p>{information.aboutContent}</p>
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
